
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/pt-br'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(updateLocale)

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)



// defaultLocale: "pt-br"


dayjs.updateLocale("pt-br")
dayjs.locale('pt-br')


export default dayjs
