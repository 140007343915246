
import SDK from '../services';

export default defineNuxtPlugin(async (nuxtApp) => {
  const service = new SDK(nuxtApp.$config.public.apiEndpoint);

  return {
      provide: {
        service
      }
  }
});