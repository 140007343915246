// Packages
import sdk from '@terreiro-vo-rita/cms-sdk-js';

// Store
import { useUserStore } from '~/store/user';

// SDK
import SDK from './index';

export default class User {
  private store = useUserStore;

  constructor (
    private $sdk: typeof sdk,
    private _sdk: SDK
  ) {};

  public async info() {
    const store = this.store();

    const res = await this.$sdk.user.me();

    store.setInfo(res);

    return res;
  }
}