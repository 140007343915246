import revive_payload_client_Mmw8LYdpei from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_yxjdFlkS0L from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FFjjzqp1Ho from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MgXZ4C3k9x from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tdTTYZasKz from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_hJBMPEyu7I from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_xkPxLveM7S from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_pUyy70BkTa from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.28.1_typescript@5.7.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_H70YHVNzzN from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_rollup@4.28.1_sass@1.82.0_typescript@5.7.2_vite@6.0.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_60a4HOnaSF from "/opt/buildhome/repo/node_modules/.pnpm/dayjs-nuxt@2.1.11_rollup@4.28.1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_igMyLnDMdJ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.28.1_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_BwSs3JqhA2 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.28.1_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_3ee1xCzatR from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.19.2_axios@1.7.9_rollup@4.28.1_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_T36OD8c0Uo from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.19.2_axios@1.7.9_rollup@4.28.1_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_vu47o2uzQM from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.19.2_axios@1.7.9_rollup@4.28.1_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_pX6H6msIxR from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@4.28.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_I7NcvJZqBk from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.9.1_rollup@4.28.1_vite@6.0.3_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import api_GFfDXud5Cr from "/opt/buildhome/repo/plugins/api.ts";
import portal_MiZwRrPxBy from "/opt/buildhome/repo/plugins/portal.ts";
export default [
  revive_payload_client_Mmw8LYdpei,
  unhead_yxjdFlkS0L,
  router_FFjjzqp1Ho,
  payload_client_MgXZ4C3k9x,
  navigation_repaint_client_tdTTYZasKz,
  check_outdated_build_client_hJBMPEyu7I,
  chunk_reload_client_xkPxLveM7S,
  plugin_vue3_pUyy70BkTa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_H70YHVNzzN,
  plugin_60a4HOnaSF,
  switch_locale_path_ssr_igMyLnDMdJ,
  i18n_BwSs3JqhA2,
  slideovers_3ee1xCzatR,
  modals_T36OD8c0Uo,
  colors_vu47o2uzQM,
  plugin_client_pX6H6msIxR,
  plugin_I7NcvJZqBk,
  api_GFfDXud5Cr,
  portal_MiZwRrPxBy
]