import sdk from '@terreiro-vo-rita/cms-sdk-js';
import Auth from './auth';
import User from './user';
import type { AxiosError } from 'axios';

export default class {
  public $sdk = sdk;

  public auth!: Auth;

  public user!: User;

  constructor(endpoint: string) {
    this.$sdk.agent.setBaseURL(endpoint);
    const router = useRouter();
    console.info('router ', router);
    // this.$sdk.agent.axiosInstance.interceptors.response.use((v) => v,
    //   (err: AxiosError) => refreshTokenHandle(err, $api, { store, app, ...ctx } as any));

    const models = {
      Auth,
      User
    };

    // Initialize classes
    Object.keys(models).forEach((keyName: string) => {
      (this as any)[keyName.toLowerCase()] = new (models as any)[keyName](this.$sdk, this);
    });

  }
}