// Packages
import sdk from '@terreiro-vo-rita/cms-sdk-js';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import type { AxiosError } from 'axios';

// Store
import { useAuthStore } from '~/store/auth';

// SDK
import Service from './index';

export default class Auth {
  private store = useAuthStore;

  constructor (
    private $sdk: typeof sdk,
    private $service: InstanceType<typeof Service>
  ) {};

  public async signIn(email: string, password: string) {
    const store = this.store();

    const res = await this.$service.$sdk.auth.signIn(email, password);

    this.$sdk.agent.setToken(res.access_token);
    store.setTokens(res.access_token, res.refresh_token);

    // Set the cookies
    Cookies.set('naat', res.access_token, { expires: dayjs().add(15, 'days').toDate() });
    Cookies.set('nprt', 'true', { expires: dayjs().add(29, 'days').toDate() });
    Cookies.set('nprt', res.refresh_token, { expires: dayjs().add(29, 'days').toDate() });

    await this.$service.user.info();
  }

  public async signOut () {
    const store = this.store();
    Cookies.remove('naat');
    Cookies.remove('nprt');
    Cookies.remove('nprt');
    store.reset();

    await this.$service.auth.signOut();
  }

  public async load() {
    const access_token = Cookies.get('naat');

    if (access_token) {
      this.$sdk.agent.setToken(access_token);
      try {
        await this.$service.user.info();
        return 200;
      } catch (err: any) {
        if ((<AxiosError>err).response?.status === 401) {
          return 401;
        }
      }
    }
  }
}