import { default as categories31ezCp5HaXMeta } from "/opt/buildhome/repo/pages/blog/categories.vue?macro=true";
import { default as _91id_93mHSP7a6lOwMeta } from "/opt/buildhome/repo/pages/blog/post/edit/[id].vue?macro=true";
import { default as newity4U6kNDRMeta } from "/opt/buildhome/repo/pages/blog/post/new.vue?macro=true";
import { default as _91id_93JKOTzL4dyaMeta } from "/opt/buildhome/repo/pages/blog/post/show/[id].vue?macro=true";
import { default as postsIxZeZBVTy4Meta } from "/opt/buildhome/repo/pages/blog/posts.vue?macro=true";
import { default as eventsPWXk02Yw7OMeta } from "/opt/buildhome/repo/pages/events.vue?macro=true";
import { default as faqs2iv9uQ2ZFvMeta } from "/opt/buildhome/repo/pages/faqs.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as passwordoSgjpjA0jZMeta } from "/opt/buildhome/repo/pages/settings/password.vue?macro=true";
import { default as preferences9w8KFHzMCDMeta } from "/opt/buildhome/repo/pages/settings/preferences.vue?macro=true";
import { default as profilepaxLzrYNK7Meta } from "/opt/buildhome/repo/pages/settings/profile.vue?macro=true";
import { default as sign_inech4x0d7MFMeta } from "/opt/buildhome/repo/pages/sign_in.vue?macro=true";
import { default as usersSKkXId5hNDMeta } from "/opt/buildhome/repo/pages/users.vue?macro=true";
export default [
  {
    name: "blog-categories___br",
    path: "/blog/categories",
    meta: categories31ezCp5HaXMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/categories.vue")
  },
  {
    name: "blog-post-edit-id___br",
    path: "/blog/post/edit/:id()",
    meta: _91id_93mHSP7a6lOwMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/post/edit/[id].vue")
  },
  {
    name: "blog-post-new___br",
    path: "/blog/post/new",
    meta: newity4U6kNDRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/post/new.vue")
  },
  {
    name: "blog-post-show-id___br",
    path: "/blog/post/show/:id()",
    meta: _91id_93JKOTzL4dyaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/post/show/[id].vue")
  },
  {
    name: "blog-posts___br",
    path: "/blog/posts",
    meta: postsIxZeZBVTy4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/blog/posts.vue")
  },
  {
    name: "events___br",
    path: "/events",
    meta: eventsPWXk02Yw7OMeta || {},
    component: () => import("/opt/buildhome/repo/pages/events.vue")
  },
  {
    name: "faqs___br",
    path: "/faqs",
    meta: faqs2iv9uQ2ZFvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/faqs.vue")
  },
  {
    name: "index___br",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "settings-password___br",
    path: "/settings/password",
    meta: passwordoSgjpjA0jZMeta || {},
    component: () => import("/opt/buildhome/repo/pages/settings/password.vue")
  },
  {
    name: "settings-preferences___br",
    path: "/settings/preferences",
    meta: preferences9w8KFHzMCDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/settings/preferences.vue")
  },
  {
    name: "settings-profile___br",
    path: "/settings/profile",
    meta: profilepaxLzrYNK7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/settings/profile.vue")
  },
  {
    name: "sign_in___br",
    path: "/sign_in",
    meta: sign_inech4x0d7MFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sign_in.vue")
  },
  {
    name: "users___br",
    path: "/users",
    meta: usersSKkXId5hNDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/users.vue")
  }
]